export const cfg_keylockr = {
	name: "Content Production Board Web",
	service_id: "10",
	sso_link: "keylockr://sso?svc_id={svc_id}&tmp_id={tmp_id}&token={token}",
	prompt_md: {
		prepare: "Preparing KeyLockr",
		expired: "**QR Code** expired. Click to retry.",
		scan: "Scan with your **KeyLockr** app to login.",
		timeout: "Timeout: {sec} seconds",
	},
};
