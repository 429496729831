/** Refer to the same file in df-backend-02 for more configurable fields */

/* ... removed ...*/
type TExtendedBEAppInfo = TBEAppInfo & {
	keylockr: {
		encrypt_secret_key: string;
		encrypt_public_key: string;
	};
};
export const be_app_info: Partial<TExtendedBEAppInfo> = {
	/** Name of the project. The database will be created based on this name */
	prj_name: "cpb",

	/** Major, minor, patch */
	be_app_ver: "1.0.00",

	//----------------------------------------------------
	/* ... removed ...*/
	//====================================================

	sales_power: {
		enabled: true,
		//----------------------------------------------------
		/* ... removed ...*/
		//====================================================
	},

	//[END-NO-SYNC-TO-FE]

	//----------------------------------------------//
	//					Languages					//
	//----------------------------------------------//

	//----------------------------------------------
	// KeyLockr Data
	//----------------------------------------------
	keylockr: {
		encrypt_secret_key: "",
		encrypt_public_key: "",
	},
};
