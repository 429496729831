import { DialogBox, IDiag } from "df-frontend-02/dist/sys/modules/dialog-box/dialog-box";
import { cfg_loginDlg } from "df-frontend-02/dist/sys/page-parts/login/api/show-dialog--login";
import { ReactModel } from "df-react-model/dist/react-model/react-model";
import { HLoginWithKeyLockr } from "./login-with-keylockr";
import { redirectAfterLogin } from "df-frontend-02/dist/sys/page-parts/login/api/redirect-after-login";

let diag: DialogBox | null = null;

cfg_loginDlg.showDialog_Login = async (allow_redirect: boolean) => {
	const model = new ReactModel({ model: { user_email_or_phone: "", pwd: "" } });

	const model_can_login = model.getModel({
		key: "can_login",
		getter: state => ({
			disabled: !(state.pwd?.length > 3 && state.user_email_or_phone?.length > 5),
		}),
	});

	const data: IDiag = {
		title: "Login",
		content: [
			<div>
				<div
					className="alert alert-primary mb-3"
					style={{ paddingTop: 5, paddingBottom: 0 }}
				>
					<HLoginWithKeyLockr onSuccess={() => loginSuccess(allow_redirect)} />
				</div>
			</div>,
		],
		onClose: () => (diag = null),
	};
	diag = new DialogBox();
	await diag.show(data).promise();
};

function loginSuccess(allow_redirect: boolean) {
	diag?.close();
	if (allow_redirect) redirectAfterLogin();
}
