// Frontend configuration file
// Do not import. This is loaded at startup

import {
	sys_menu_language,
	sys_menu_table_mgr,
	sys_menu_user_mgr,
	sys_menu_user_profile,
} from "df-frontend-02/dist/apps/app/app-predef-menu";

export function getAppInfo(): Partial<IAppInfo> {
	const ret: Partial<IAppInfo> = {
		app_name: "Content Production Board",
		//browser title
		doc_title_template: "{0} - Content Production Board",
		footer_line: "© GJW 2024. All rights reserved.",

		path_after_logout: "/",
		path_to_home: "/",
		path_after_login: "/",
		path_to_login: "/landing",
		// login_types: {
		// 	ganjing_auth: false,
		// 	google: false,
		// 	name_pwd: true,
		// },

		/** do not load app-info.js from server db */
		merge_with_db: true,

		navbar: {
			color: "black",

			/** left menu will completely replace the menu in the package */
			left_menu: [
				{
					title: trans("MENU01", "Home"),
					roles: ["everyone"],
					href: "/",
				},
				//[CODE-GEN-MENU]
				{
					title: "DevHelp",
					roles: ["admin"],
					sub_menus: [
						{
							title: "Instella",
							roles: ["everyone"],
							href: "help/cms",
						},
						{
							title: "Frontend Building Blocks",
							roles: ["everyone"],
							href: "help/fe-building-blocks",
						},
						{
							title: "Backend Building Blocks",
							roles: ["everyone"],
							href: "help/be-building-blocks",
						},
						{
							title: "Cypress test",
							roles: ["everyone"],
							href: "help/cypress-test",
						},
						{
							title: "-",
							roles: ["everyone"],
						},
						{
							title: "Sample table - Score",
							roles: ["everyone"],
							href: "/data-grid/resource-score",
						},
						{
							title: "Sample page - My page",
							roles: ["everyone"],
							href: "/my-page",
						},
					],
				},
				{
					title: sys_menu_user_mgr,
				},
				// {
				// 	title: sys_menu_table_mgr,
				// },
			],
			right_menu: [{ title: sys_menu_language }, { title: sys_menu_user_profile }],
			sticky: false,
		},

		// apiPort: number, //api port number

		// urlAfterLogin: string;
		// urlHome: string;

		// beEndPoint: () => "http://localhost:3333",

		// show_breadcrumb: true,
	};
	return ret;
}
