//--------------------------------------------------------------------------------------
// Prepare the configuration for the package
//--------------------------------------------------------------------------------------

import { mergeObject } from "df-helpers/dist/merge-object/merge-object";
import { g_system_events } from "df-frontend-02/dist/g-sys-events";
import { getAppInfo } from "./app-info";

//------ register app pages --------

export function initEvents() {
	//register app-info.tsx in the event
	if (g_system_events.getCount("app-info:before_use(sync)") !== 0) {
		//event has fired, the following will never get called
		throw Error("(IET14)");
	}
	g_system_events.on(
		"app-info:before_use(sync)",
		({ app_info }) => {
			const local = getAppInfo();
			mergeObject(app_info, local, { array: "no-merge" });
		},
		undefined,
		50,
	);
}

initEvents();
